'use client'
import { useEffect } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'

export function PageView(props: any) {
  useEffect(() => {
    sendGTMEvent({
      event: 'custom_page_view',
      ...props,
    })
  }, [])

  return null
}
